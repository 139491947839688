import React from "react"
import Qa from "./Qa"
import * as styles from "./styles.module.css"

const Two = ({ data }) => {
  return (
    <section className={styles.two}>
      <p className={styles.hdg}>General Questions</p>
      <div className={styles.faqs}>
        {data.general.map((item, index) => {
          return <Qa q={item.q} a={item.a} key={index} />
        })}
      </div>

      <p className={styles.hdg}>Account Questions</p>
      <div className={styles.faqs}>
        {data.account.map((item, index) => {
          return <Qa q={item.q} a={item.a} key={index} />
        })}
      </div>
    </section>
  )
}
export default Two
