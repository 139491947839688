import React from "react"
import H1 from "../../components/H1"
import * as styles from "./styles.module.css"

const One = () => {
  return (
    <section className={styles.one}>
      <H1 text="Frequently Asked Questions" addTopMargin={true}></H1>

      <div className={styles.grid}>
        <div>
          <span>
            Answers to common FAQs including account and technical support.
          </span>
        </div>
      </div>
    </section>
  )
}

export default One
