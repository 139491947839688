import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronDown } from "@fortawesome/pro-light-svg-icons"
import * as styles from "./styles.module.css"

const Qa = ({ a, q }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={styles.qaContainer}>
      <button type="button" className="icon" onClick={() => setOpen(!open)}>
        <div>
          <h3>{q}</h3>
          <FontAwesomeIcon
            icon={open ? faChevronDown : faChevronRight}
            size="lg"
          />
        </div>
      </button>

      <p className={open ? "" : styles.hidden}>{a}</p>
    </div>
  )
}

export default Qa
