import React, { useState, useEffect, Fragment } from "react"
import { API_URL } from "../utils/helpers"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../faqsPage/One"
import Two from "../faqsPage/Two"
import Footer from "../components/Footer"

const FaqsPage = () => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const init = async id => {
      const url = new URL(`${API_URL}/faqs`)

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        })
        const json = await response.json()
        if (json && json.resp === 1) {
          setData({ ...json })
        }
        setLoading(false)
      } catch (error) {}
    }

    init()
  }, [])

  return (
    <Fragment>
      <Helmet
        title="Frequently Asked Questions about the ETF Stock Report"
        descr="The ETF Stock Report is for people who want more control of their stock investments in order to outperform the market. Read our FAQs to learn more about our investment advice newsletter."
        slug="/faqs"
      />
      <Header />
      <One />
      {!loading && <Two data={data} />}
      <Footer />
    </Fragment>
  )
}

export default FaqsPage
